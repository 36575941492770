









import Vue from "vue";
import PrivacyText from "@/components/PrivacyText.vue";
import ImprintText from "@/components/ImprintText.vue";
export default Vue.extend({
  components: {
    PrivacyText,
    ImprintText,
  },
});
